<router-outlet></router-outlet>


<div class="menu" [class.active]="dataService.showMenu">
    <i class="fat fa-times" style="right: 1.5rem; top: 2rem; position: absolute;" (click)="dataService.showMenu = false"></i>
    <ul>
        <li [class.current]="dataService.currentPage == 'home'" (click)="navigate('/')">
            <span>Home</span>
          </li>
      
          <li [class.current]="dataService.currentPage == 'job-ad'" (click)="navigate('/jobs')">
            <span>Job finden</span>
          </li>
      
          <li (click)="openKandiboard()">
            <span>Direkt bewerben</span>
          </li>
          <li [class.current]="dataService.currentPage == 'companies'" (click)="navigate('/unternehmen')">
            <span>Unternehmen</span>
          </li>
          <!-- <li [class.current]="dataService.currentPage == 'faq'" (click)="navigate('/about/faq')">
            <span>FAQs</span>
          </li> -->
      
          <li [class.current]="dataService.currentPage == 'contact'" (click)="navigate('/about/contact')">
            <span>Kontakt</span>
          </li>
        </ul>
  </div>