import { Component } from "@angular/core";
import { doc, setDoc } from "@angular/fire/firestore";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-user-set-static",
  templateUrl: "./user-set-static.component.html",
  styleUrls: ["./user-set-static.component.scss"],
})
export class UserSetStaticComponent {
  constructor(public authService: AuthService) {}

  public static:any;
  public staticMap:any;

  setStatic() {
    setDoc(doc(this.authService.firestore, 'static', "app"),
        {
          berufsfelder: [
            {
              id: "banken",
              name: "Banken, Finanzen, Versicherung",
              iconClass: "far fa-money-check-edit",
              flow: "standard",
            },
            {
              id: "bau",
              name: "Bau, Gebäudetechnik",
              iconClass: "far fa-user-hard-hat",
              flow: "standard",
            },
            {
              id: "gesundheitswesen",
              name: "Beauty, Gesundheit, Soziales",
              iconClass: "far fa-briefcase-medical",
              flow: "standard",
            },
            {
              id: "controlling",
              name: "Controlling",
              iconClass: "far fa-chart-bar",
              flow: "standard",
            },
            {
              id: "design",
              name: "Design",
              iconClass: "far fa-spray-can",
              flow: "standard",
            },
            {
              id: "innovationsmanagement_digitalisierung",
              name: "Digitalisierung",
              iconClass: "far fa-lightbulb-on",
              flow: "standard",
            },
            {
              id: "einkauf_und_supply_chain",
              name: "Einkauf und Supply Chain",
              iconClass: "far fa-shopping-basket",
              flow: "standard",
            },
            {
              id: "elektro",
              name: "Elektrotechnik",
              iconClass: "far fa-bolt",
              flow: "standard",
            },
            // {
            //   id: "engineering",
            //   name: "Engineering",
            //   iconClass: "far fa-pencil-ruler",
            //   flow: "standard",
            // },
            {
              id: "finanz_und_rechnungswesen",
              name: "Finanz- und Rechnungswesen",
              iconClass: "far fa-abacus",
              flow: "standard",
            },
            // {
            //   id: "forschung",
            //   name: "Forschung & Entwicklung",
            //   iconClass: "far fa-brain",
            //   flow: "standard",
            // },
            {
              id: "gastgewerbe",
              name: "Gastgewerbe",
              iconClass: "far fa-utensils",
              flow: "standard",
            },
            {
              id: "einzelhandel",
              name: "Handel",
              iconClass: "far fa-store",
              flow: "standard",
            },
            {
              id: "informatik",
              name: "IT",
              iconClass: "far fa-code",
              flow: "standard",
            },
            {
              id: "kunststoffe",
              name: "Kunststoffe",
              iconClass: "far fa-prescription-bottle",
              flow: "standard",
            },
            {
              id: "branche_kultur_unterhaltung_veranstaltung",
              name: "Kunst, Kultur, Veranstaltung",
              iconClass: "far fa-theater-masks",
            },
            {
              id: "land_forstwirtschaft",
              name: "Land-, Forstwirtschaft, Garten",
              iconClass: "far fa-tractor",
              flow: "standard",
            },
            {
              id: "lebensmittelindustrie",
              name: "Lebensmittel",
              iconClass: "far fa-utensils-alt",
              flow: "standard",
            },
            {
              id: "marketing",
              name: "Marketing",
              iconClass: "far fa-megaphone",
              flow: "standard",
            },
            {
              id: "maschinenbau_kfz_metall",
              name: "Maschinenbau, Kfz, Metall",
              iconClass: "far fa-car-mechanic",
              flow: "standard",
            },
            {
              id: "office_und_administration",
              name: "Office und Administration",
              iconClass: "far fa-chair-office",
              flow: "standard",
            },
            {
              id: "human_resources",
              name: "Personalwesen / HR",
              iconClass: "far fa-people-arrows",
              flow: "standard",
            },
            {
              id: "projektmanagement",
              name: "Projektmanagement",
              iconClass: "far fa-project-diagram",
              flow: "standard",
            },
            {
              id: "recht",
              name: "Rechtsberufe",
              iconClass: "far fa-gavel",
              flow: "standard",
            },
            {
              id: "logistik",
              name: "Spedition",
              iconClass: "far fa-warehouse-alt",
              flow: "standard",
            },
            {
              id: "tourismus",
              name: "Tourismus",
              iconClass: "far fa-island-tropical",
              flow: "standard",
            },
            // {
            //   id: "top_level_management",
            //   name: "Top-Level-Management",
            //   iconClass: "far fa-user-tie",
            //   flow: "standard",
            // },
            {
              id: "verkehr",
              name: "Verkehr",
              iconClass: "far fa-traffic-light",
              flow: "standard",
            },
            {
              id: "sales_und_business_development",
              name: "Vertrieb, Business Development",
              iconClass: "far fa-handshake",
              flow: "standard",
            },
            {
              id: "wissenschaft",
              name: "Wissenschaft",
              iconClass: "far fa-atom",
              flow: "standard",
            },
            {
              id: "verwaltung",
              name: "Verwaltung",
              iconClass: "far fa-landmark",
              flow: "standard",
            },
            {
              id: "gewerbliche_fachkraefte",
              name: "Gewerbliche Fach- und Führungskräfte/Techniker/Meister",
              iconClass: "far fa-cogs",
              flow: "gewerbliche_technische_hilfskraefte",
            },
            {
              id: "gewerbliche_hilfskraefte",
              name: "Gewerbliche Hilfskräfte",
              iconClass: "far fa-hands-helping",
              flow: "gewerbliche_technische_hilfskraefte",
            },
            {
              id: "sonstiges",
              name: "Sonstiges",
              iconClass: "far fa-question-square",
              flow: "standard",
            },
          ],
          anstellungsarten: [
            {
              id: "anstellungsart_festanstellung",
              name: "Festanstellung",
            },
            {
              id: "anstellungsart_freelance",
              name: "Freelance",
            },
            {
              id: "anstellungsart_befristet",
              name: "Befristet",
            },
            {
              id: "anstellungsart_ausbildung",
              name: "Ausbildung",
            },
            {
              id: "anstellungsart_duales_studium",
              name: "Duales Studium",
            },
            {
              id: "anstellungsart_praktikum",
              name: "Praktikum",
            },
            {
              id: "anstellungsart_pflichtpraktikum",
              name: "Pflichtpraktikum",
            },
            {
              id: "anstellungsart_abschlussarbeit",
              name: "Abschlussarbeit",
            },
            {
              id: "anstellungsart_promotion",
              name: "Promotion",
            },
            {
              id: "anstellungsart_ferienbeschaeftigung",
              name: "Ferienbeschäftigung",
            },
            {
              id: "anstellungsart_werkstudent",
              name: "Werkstudent",
            },
            {
              id: "arbeitnehmerueberlassung",
              name: "Arbeitnehmerüberlassung",
            },
          ],
          verfuegbarkeit: [
            {
              id: "verfuegbarkeit_per_sofort",
              name: "per sofort",
              alternativNamen: ["asap"],
            },
            // {
            //   id: 'datum',
            //   name:'Ab Datum'
            // },
            {
              id: "verfuegbarkeit_4_WzM",
              name: "4 Wochen zum Monatsende",
            },
            {
              id: "verfuegbarkeit_2_MzM",
              name: "2 Monate zum Monatsende",
            },
            {
              id: "verfuegbarkeit_2_MzQ",
              name: "2 Monate zum Quartalsende",
            },
            {
              id: "verfuegbarkeit_3_MzM",
              name: "3 Monate zum Monatsende",
            },
            {
              id: "verfuegbarkeit_3_MzQ",
              name: "3 Monate zum Quartalsende",
            },
            {
              id: "verfuegbarkeit_4_MzM",
              name: "4-6 Monate zum Monatsende",
            },
            {
              id: "verfuegbarkeit_4_MzQ",
              name: "4-6 Monate zum Quartalsende",
            },
            {
              id: "verfuegbarkeit_nach_Absprache",
              name: "nach Absprache",
            },
            {
              id: "verfuegbarkeit_sonstige",
              name: "Sonstige",
            },
          ],
          arbeitszeit: [
            {
              id: "arbeitszeit_VZ",
              name: "Vollzeit",
              iconClass: "fas fa-hourglass",
              alternativNamen: ["VZ"],
            },
            {
              id: "arbeitszeit_TZ",
              name: "Teilzeit",
              iconClass: "fas fa-hourglass-half",
              alternativNamen: ["TZ"],
            },
            {
              id: "arbeitszeit_450",
              iconClass: "fas fa-money-bill-alt",
              name: "450€",
            },
          ],
          arbeitsort: [
            {
              id: "umkreis_wohnort",
              name: "Umkreis von Wohnort",
            },
            {
              id: "umkreis_ort",
              name: "Umkreis von anderem Ort",
            },
            {
              id: "bundesland",
              name: "Bundesland",
            },
            {
              id: "land",
              name: "Land",
            },
          ],
          fuehrungserfahrung: [
            {
              id: "fuehrungserfahrung_keine",
              name: "keine",
              rang: 1,
            },
            {
              id: "fuehrungserfahrung_bis_3_jahre",
              name: "bis 3 Jahre",
              rang: 2,
            },
            {
              id: "fuehrungserfahrung_groesser_3_jahre",
              name: "größer 3 Jahre",
              rang: 3,
            },
            {
              id: "fuehrungserfahrung_groesser_8_jahre",
              name: "größer 8 Jahre",
              rang: 4,
            },
          ],

          berufserfahrung: [
            {
              id: "berufserfahrung_bis_1_jahr",
              name: "< 1 Jahr",
              rang: 1,
            },
            {
              id: "berufserfahrung_1_bis_3_jahre",
              name: "1 - 3 Jahre",
              rang: 2,
            },
            {
              id: "berufserfahrung_4_bis_10_jahre",
              name: "4 - 10 Jahre",
              rang: 3,
            },
            {
              id: "berufserfahrung_groesser_10_jahre",
              name: "> 10 Jahre",
              rang: 4,
            },
          ],

          karrierelevel: [
            {
              id: "karrierelevel_junior",
              name: "Junior",
              iconClass: "fas fa-baby",
              info: "1-2 Jahre Berufserfahrung",
              rang: 1,
            },
            {
              id: "karrierelevel_middle",
              name: "Middle",
              iconClass: "fas fa-child",
              info: "3-5 Jahre Berufserfahrung",
              rang: 2,
            },
            {
              id: "karrierelevel_senior",
              name: "Senior",
              iconClass: "fas fa-user",
              info: ">5 Jahre Berufserfahrung",
              rang: 3,
            },
            {
              id: "karrierelevel_manager",
              name: "Manager",
              iconClass: "fas fa-user-tie",
              info: "Personalverantwortung",
              rang: 4,
            },
          ],
          // 'stellenbezeichnungen': {
          //     'finanz_und_rechnungswesen': [
          //         {
          //             'id': 'debitorenbuchhaltung',
          //             'name': 'Debitorenbuchhaltung (m/w/d)'
          //         },
          //         {
          //             'id': 'kreditorenbuchhaltung',
          //             'name': 'Kreditorenbuchhaltung (m/w/d)'
          //         },
          //         {
          //             'id': 'sachbearbeitung_buchhaltung',
          //             'name': 'Sachbearbeitung Buchhaltung (m/w/d)'
          //         },
          //         {
          //             'id': 'anlagenbuchhaltung',
          //             'name': 'Anlagenbuchhaltung (m/w/d)'
          //         },
          //         {
          //             'id': 'hauptbuchhaltung',
          //             'name': 'Hauptbuchhaltung (m/w/d)'
          //         },
          //         {
          //             'id': 'bilanzbuchhaltung_national',
          //             'name': 'Bilanzbuchhaltung national (m/w/d)'
          //         },
          //         {
          //             'id': 'bilanzbuchhaltung_international',
          //             'name': 'Bilanzbuchhaltung international (m/w/d)'
          //         },
          //         {
          //             'id': 'referent_rechnungswesen',
          //             'name': 'Referent Rechnungswesen (m/w/d)'
          //         },
          //         {
          //             'id': 'group_accountant',
          //             'name': 'Group Accountant (m/w/d)'
          //         },
          //         {
          //             'id': 'teamleiter_buchhaltung',
          //             'name': 'Teamleiter Buchhaltung (m/w/d)'
          //         },
          //         {
          //             'id': 'leiter_buchhaltung',
          //             'name': 'Leiter Buchhaltung (m/w/d)'
          //         },
          //         {
          //             'id': 'leiter_finanzen',
          //             'name': 'Leiter Finanzen (m/w/d)'
          //         },
          //         {
          //             'id': 'finance_manager',
          //             'name': 'Finance Manager (m/w/d)'
          //         },
          //         {
          //             'id': 'cfo',
          //             'name': 'CFO (m/w/d)'
          //         },
          //         {
          //             'id': 'kaufmännischer_leiter',
          //             'name': 'Kaufmännischer Leiter (m/w/d)'
          //         },
          //         {
          //             'id': 'internal_audit',
          //             'name': 'Internal Audit (m/w/d)'
          //         },
          //         {
          //             'id': 'revision',
          //             'name': 'Revision (m/w/d)'
          //         },
          //         {
          //             'id': 'steuerfachangestellter',
          //             'name': 'Steuerfachangestellter (m/w/d)'
          //         },
          //         {
          //             'id': 'tax_manager',
          //             'name': 'Tax Manager (m/w/d)'
          //         },
          //         {
          //             'id': 'steuerberater',
          //             'name': 'Steuerberater (m/w/d)'
          //         },
          //         {
          //             'id': 'wirtschaftsprüfer',
          //             'name': 'Wirtschaftsprüfer (m/w/d)'
          //         },
          //         {
          //             'id': 'experte_m&a',
          //             'name': 'Experte M&A (m/w/d)'
          //         },
          //         {
          //             'id': 'mathematiker',
          //             'name': 'Mathematiker (m/w/d)'
          //         },
          //         {
          //             'id': 'referent_meldewesen',
          //             'name': 'Referent Meldewesen (m/w/d)'
          //         },
          //         {
          //             'id': 'lohn_und_gehaltsabrechner',
          //             'name': 'Lohn- und Gehaltsabrechner (m/w/d)'
          //         },
          //         {
          //             'id': 'sonstige_finanzen',
          //             'name': 'Sonstige Finanzen (m/w/d)'
          //         },
          //         {
          //             'id': 'projektmanager_digitalisierung',
          //             'name': 'Projektmanager Digitalisierung (m/w/d)'
          //         },
          //         {
          //             'id': 'projektmanager_erpsystem',
          //             'name': 'Projektmanager ERP-System (m/w/d)'
          //         },
          //         {
          //             'id': 'treasury',
          //             'name': 'Treasury (m/w/d)'
          //         }
          //     ],
          //     'controlling': [
          //         {
          //             'id': 'financial_controller',
          //             'name': 'Financial Controller (m/w/d)'
          //         },
          //         {
          //             'id': 'projektcontoller',
          //             'name': 'Projektcontoller (m/w/d)'
          //         },
          //         {
          //             'id': 'produktionscontrolling',
          //             'name': 'Produktionscontrolling (m/w/d)'
          //         },
          //         {
          //             'id': 'werkscontrolling',
          //             'name': 'Werkscontrolling (m/w/d)'
          //         },
          //         {
          //             'id': 'business_unit_controlling',
          //             'name': 'Business Unit Controlling (m/w/d)'
          //         },
          //         {
          //             'id': 'financial_analyst',
          //             'name': 'Financial Analyst (m/w/d)'
          //         },
          //         {
          //             'id': 'business_analyst',
          //             'name': 'Business Analyst (m/w/d)'
          //         },
          //         {
          //             'id': 'personalcontrolling',
          //             'name': 'Personalcontrolling (m/w/d)'
          //         },
          //         {
          //             'id': 'kalkulator',
          //             'name': 'Kalkulator (m/w/d)'
          //         },
          //         {
          //             'id': 'teamleiter_controlling',
          //             'name': 'Teamleiter Controlling (m/w/d)'
          //         },
          //         {
          //             'id': 'referent_controlling',
          //             'name': 'Referent Controlling (m/w/d)'
          //         },
          //         {
          //             'id': 'beteiligungscontrolling',
          //             'name': 'Beteiligungscontrolling (m/w/d)'
          //         },
          //         {
          //             'id': 'leiter_controlling',
          //             'name': 'Leiter Controlling (m/w/d)'
          //         },
          //         {
          //             'id': 'finance_manager',
          //             'name': 'Finance Manager (m/w/d)'
          //         },
          //         {
          //             'id': 'wirtschaftsprüfer',
          //             'name': 'Wirtschaftsprüfer (m/w/d)'
          //         },
          //         {
          //             'id': 'experte_m&a',
          //             'name': 'Experte M&A (m/w/d)'
          //         },
          //         {
          //             'id': 'cfo',
          //             'name': 'CFO (m/w/d)'
          //         },
          //         {
          //             'id': 'kaufmännischer_leiter',
          //             'name': 'Kaufmännischer Leiter (m/w/d)'
          //         },
          //         {
          //             'id': 'projektmanager_digitalisierung',
          //             'name': 'Projektmanager Digitalisierung (m/w/d)'
          //         },
          //         {
          //             'id': 'projektmanager_erpsystem',
          //             'name': 'Projektmanager ERP-System (m/w/d)'
          //         },
          //         {
          //             'id': 'vertriebscontrolling',
          //             'name': 'Vertriebscontrolling (m/w/d)'
          //         }
          //     ],
          //     'human_resources': [
          //         {
          //             'id': 'personalsachbearbeiter',
          //             'name': 'Personalsachbearbeiter (m/w/d)'
          //         },
          //         {
          //             'id': 'personalreferent',
          //             'name': 'Personalreferent (m/w/d)'
          //         },
          //         {
          //             'id': 'hr_business_partner',
          //             'name': 'HR Business Partner (m/w/d)'
          //         },
          //         {
          //             'id': 'recruiter',
          //             'name': 'Recruiter (m/w/d)'
          //         },
          //         {
          //             'id': 'personalentwickler',
          //             'name': 'Personalentwickler (m/w/d)'
          //         },
          //         {
          //             'id': 'lohn_und_gehaltsabrechner',
          //             'name': 'Lohn- und Gehaltsabrechner (m/w/d)'
          //         },
          //         {
          //             'id': 'leiter_personal',
          //             'name': 'Leiter Personal (m/w/d)'
          //         },
          //         {
          //             'id': 'experte_arbeitsrecht',
          //             'name': 'Experte Arbeitsrecht (m/w/d)'
          //         },
          //         {
          //             'id': 'personalcontroller',
          //             'name': 'Personalcontroller (m/w/d)'
          //         }
          //     ],
          //     'office_und_administration': [
          //         {
          //             'id': 'assistenz_abteilungteam',
          //             'name': 'Assistenz Abteilung/Team (m/w/d)'
          //         },
          //         {
          //             'id': 'assistenz_geschäftsführung',
          //             'name': 'Assistenz Geschäftsführung (m/w/d)'
          //         },
          //         {
          //             'id': 'sachbearbeitung',
          //             'name': 'Sachbearbeitung (m/w/d)'
          //         },
          //         {
          //             'id': 'referent_geschäftsführung',
          //             'name': 'Referent Geschäftsführung (m/w/d)'
          //         },
          //         {
          //             'id': 'empfangsmitarbeiter',
          //             'name': 'Empfangsmitarbeiter (m/w/d)'
          //         },
          //         {
          //             'id': 'fuhrpark_verantwortlicher',
          //             'name': 'Fuhrpark Verantwortlicher (m/w/d)'
          //         },
          //         {
          //             'id': 'office_manager',
          //             'name': 'Office Manager (m/w/d)'
          //         }
          //     ],
          //     'sales_und_business_development': [
          //         {
          //             'id': 'vertriebsinnendienst',
          //             'name': 'Vertriebsinnendienst (m/w/d)'
          //         },
          //         {
          //             'id': 'vertriebsaussendienst',
          //             'name': 'Vertriebsaussendienst (m/w/d)'
          //         },
          //         {
          //             'id': 'key_account_manager',
          //             'name': 'Key Account Manager (m/w/d)'
          //         },
          //         {
          //             'id': 'account_manager',
          //             'name': 'Account Manager (m/w/d)'
          //         },
          //         {
          //             'id': 'business_development_manager',
          //             'name': 'Business Development Manager (m/w/d)'
          //         },
          //         {
          //             'id': 'gebietsleiter',
          //             'name': 'Gebietsleiter (m/w/d)'
          //         },
          //         {
          //             'id': 'vertriebsleiter',
          //             'name': 'Vertriebsleiter (m/w/d)'
          //         },
          //         {
          //             'id': 'cso',
          //             'name': 'CSO (m/w/d)'
          //         }
          //     ],
          //     'einkauf_und_supply_chain': [
          //         {
          //             'id': 'einkaufssachbearbeiter',
          //             'name': 'Einkaufssachbearbeiter (m/w/d)'
          //         },
          //         {
          //             'id': 'operativer_einkäufer',
          //             'name': 'Operativer Einkäufer (m/w/d)'
          //         },
          //         {
          //             'id': 'strategischer_einkäufer',
          //             'name': 'Strategischer Einkäufer (m/w/d)'
          //         },
          //         {
          //             'id': 'lead_buyer',
          //             'name': 'Lead Buyer (m/w/d)'
          //         },
          //         {
          //             'id': 'technischer_einkäufer',
          //             'name': 'Technischer Einkäufer (m/w/d)'
          //         },
          //         {
          //             'id': 'supply_chain_manager',
          //             'name': 'Supply Chain Manager (m/w/d)'
          //         },
          //         {
          //             'id': 'leiter_einkauf',
          //             'name': 'Leiter Einkauf (m/w/d)'
          //         }
          //     ],
          //     'marketing': [
          //         {
          //             'id': 'referent_marketing',
          //             'name': 'Referent Marketing (m/w/d)'
          //         },
          //         {
          //             'id': 'produktmanager',
          //             'name': 'Produktmanager (m/w/d)'
          //         },
          //         {
          //             'id': 'leiter_kommunikation',
          //             'name': 'Leiter Kommunikation (m/w/d)'
          //         },
          //         {
          //             'id': 'leiter_marketing',
          //             'name': 'Leiter Marketing (m/w/d)'
          //         },
          //         {
          //             'id': 'eventmanager',
          //             'name': 'Eventmanager (m/w/d)'
          //         },
          //         {
          //             'id': 'digital_marketing',
          //             'name': 'Digital Marketing (m/w/d)'
          //         },
          //         {
          //             'id': 'contentmanager',
          //             'name': 'Contentmanager (m/w/d)'
          //         },
          //         {
          //             'id': 'mitarbeiter_kommunikation',
          //             'name': 'Mitarbeiter Kommunikation (m/w/d)'
          //         },
          //         {
          //             'id': 'mediaberatung',
          //             'name': 'Mediaberatung (m/w/d)'
          //         },
          //         {
          //             'id': 'social_media_manager',
          //             'name': 'Social Media Manager (m/w/d)'
          //         }
          //     ],
          //     'innovation': [
          //         {
          //             'id': 'innovationsmanager',
          //             'name': 'Innovationsmanager (m/w/d)'
          //         },
          //         {
          //             'id': 'startup_scout',
          //             'name': 'Startup Scout (m/w/d)'
          //         },
          //         {
          //             'id': 'manager_digitalisierung',
          //             'name': 'Manager Digitalisierung (m/w/d)'
          //         },
          //         {
          //             'id': 'projektmanagement',
          //             'name': 'Projektmanagement (m/w/d)'
          //         },
          //         {
          //             'id': 'venture_development',
          //             'name': 'Venture Development (m/w/d)'
          //         },
          //         {
          //             'id': 'investment_manager',
          //             'name': 'Investment Manager (m/w/d)'
          //         },
          //         {
          //             'id': 'business_anaylst',
          //             'name': 'Business Anaylst (m/w/d)'
          //         },
          //         {
          //             'id': 'cdo',
          //             'name': 'CDO (m/w/d)'
          //         }
          //     ],
          //     'logistik': [
          //         {
          //             'id': 'berufskraftfahrer',
          //             'name': 'Berufskraftfahrer (m/w/d)'
          //         },
          //         {
          //             'id': 'kaufmann_spedition_logistikdienstleistungen',
          //             'name': 'Kaufmann/frau für Spedition/Logistikdienstleistungen (m/w/d)'
          //         },
          //         {
          //             'id': 'fachkraft_lagerlogistik',
          //             'name': 'Fachkraft Lagerlogistik (m/w/d)'
          //         },
          //         {
          //             'id': 'fachlagerist',
          //             'name': 'Fachlagerist (m/w/d)'
          //         },
          //         {
          //             'id': 'fluglotse',
          //             'name': 'Fluglotse (m/w/d)'
          //         },
          //         {
          //             'id': 'fachkraft_kurier_express_postdienstleistungen',
          //             'name': 'Fachkraft Kurier-, Express-, Postdienstleistungen (m/w/d)'
          //         },
          //         {
          //             'id': 'fachkraft_strassen_verkehrstechnik',
          //             'name': 'Fachkraft Straßen-, Verkehrstechnik (m/w/d)'
          //         },
          //         {
          //             'id': 'pilot',
          //             'name': 'Pilot (m/w/d)'
          //         },
          //         {
          //             'id': 'kaufmann_luftverkehr',
          //             'name': 'Kaufmann-/frau Luftverkehr (m/w/d)'
          //         },
          //     ],
          //     'gewerbliche_fachkraefte': [
          //         {
          //             'id': 'elektromonteur',
          //             'name': 'Elektromonteur (m/w/d)'
          //         },
          //         {
          //             'id': 'elektriker',
          //             'name': 'Elektriker (m/w/d)'
          //         },
          //         {
          //             'id': 'elektroinstallateure',
          //             'name': 'Elektroinstallateure (m/w/d)'
          //         },
          //         {
          //             'id': 'schlosser',
          //             'name': 'Schlosser (m/w/d)'
          //         },
          //         {
          //             'id': 'schweißer',
          //             'name': 'Schweißer (m/w/d)'
          //         },
          //         {
          //             'id': 'zerspanungsmechaniker',
          //             'name': 'Zerspanungsmechaniker (m/w/d)'
          //         },
          //         {
          //             'id': 'mechaniker',
          //             'name': 'Mechaniker (m/w/d)'
          //         },
          //         {
          //             'id': 'mechatroniker',
          //             'name': 'Mechatroniker (m/w/d)'
          //         },
          //         {
          //             'id': 'metallbauer',
          //             'name': 'Metallbauer (m/w/d)'
          //         },
          //         {
          //             'id': 'werkzeugmacher',
          //             'name': 'Werkzeugmacher (m/w/d)'
          //         },
          //         {
          //             'id': 'karosseriebauer',
          //             'name': 'Karosseriebauer (m/w/d)'
          //         },
          //         {
          //             'id': 'maler_lackierer',
          //             'name': 'Maler und Lackierer (m/w/d)'
          //         },
          //         {
          //             'id': 'maschinen_anlagenfuehrer',
          //             'name': 'Maschinen- und Anlagenführer (m/w/d)'
          //         },
          //         {
          //             'id': 'techniker',
          //             'name': 'Techniker (m/w/d)'
          //         },{
          //             'id': 'gas_wasserinstallateure',
          //             'name': 'Gas- und Wasserinstallateure (m/w/d)'
          //         },{
          //             'id': 'tischler',
          //             'name': 'Tischler (m/w/d)'
          //         },{
          //             'id': 'koch',
          //             'name': 'Koch (m/w/d)'
          //         },{
          //             'id': 'logistik_lagerfachkräfte',
          //             'name': 'Logistik- und Lagerfachkräfte (m/w/d)'
          //         }
          //     ],
          //     'gewerbliche_hilfskraefte': [
          //         {
          //             'id': 'staplerfahrer',
          //             'name': 'Staplerfahrer (m/w/d)'
          //         },{
          //             'id': 'transportmitarbeiter',
          //             'name': 'Transportmitarbeiter (m/w/d)'
          //         },{
          //             'id': 'produktionsmitarbeiter',
          //             'name': 'Produktionsmitarbeiter (m/w/d)'
          //         },{
          //             'id': 'montagemitarbeiter',
          //             'name': 'Montagemitarbeiter (m/w/d)'
          //         },{
          //             'id': 'unterstuetzende_mitarbeiter_im_handwerk',
          //             'name': 'Unterstützende Mitarbeiter im Handwerk (m/w/d)'
          //         },{
          //             'id': 'technische_mitarbeiter',
          //             'name': 'Technische Mitarbeiter (m/w/d)'
          //         },{
          //             'id': 'lager_logistikmitarbeiter',
          //             'name': 'Lager- und Logistikmitarbeiter (m/w/d)'
          //         },{
          //             'id': 'kommissionierer',
          //             'name': 'Kommissionierer (m/w/d)'
          //         },{
          //             'id': 'kontroll_verpackungsmitarbeiter',
          //             'name': 'Kontroll- und Verpackungsmitarbeiter (m/w/d)'
          //         },{
          //             'id': 'reinigungspersonal',
          //             'name': 'Reinigungspersonal (m/w/d)'
          //         },{
          //             'id': 'regalauffueller',
          //             'name': 'Regalauffüller (m/w/d)'
          //         },
          //     ],
          //     'innovationsmanagement_digitalisierung': [
          //         // {
          //         //     'id': '',
          //         //     'name': ' (m/w/d)'
          //         // },
          //     ],
          //     'top_level_management': [
          //         {
          //             'id': 'ceo',
          //             'name': 'CEO (m/w/d)'
          //         },
          //         {
          //             'id': 'cfo',
          //             'name': 'CFO (m/w/d)'
          //         },
          //         {
          //             'id': 'cso',
          //             'name': 'CSO (m/w/d)'
          //         },
          //         {
          //             'id': 'cmo',
          //             'name': 'CMO (m/w/d)'
          //         },
          //         {
          //             'id': 'cto',
          //             'name': 'CTO (m/w/d)'
          //         },
          //         {
          //             'id': 'cio',
          //             'name': 'CIO (m/w/d)'
          //         },
          //         {
          //             'id': 'managing_director',
          //             'name': 'Managing Director (m/w/d)'
          //         },
          //         {
          //             'id': 'aufsichtsrat',
          //             'name': 'Aufsichtsrat (m/w/d)'
          //         },
          //         {
          //             'id': 'beirat',
          //             'name': 'Beirat (m/w/d)'
          //         },
          //     ],
          // },

          hoechster_abschluss: [
            {
              id: "keinabschluss",
              name: "Kein Abschluss",
              rang: 1,
            },

            {
              id: "allgemeine_hochschulreife",
              name: "Allgemeine Hochschulreife",
              rang: 3,
            },

            {
              id: "fachhochschulreife",
              name: "Fachhochschulreife",
              rang: 3,
              alternativNamen: ["Sekundarbildung Oberstufe", "Abitur"],
            },

            {
              id: "hoechster_abschluss_bachelor",
              name: "Bachelor Universität",
              rang: 4,
            },
            {
              id: "hoechster_abschluss_bachelor_fh",
              name: "Bachelor Fachhochschule",
              rang: 4,
            },

            {
              id: "master",
              name: "Master",
              rang: 4,
            },

            {
              id: "promotion",
              name: "Promotion",
              rang: 5,
            },

            {
              id: "duale_berufsausbildung",
              name: "Duale Berufsausbildung",
              rang: 3,
            },

            {
              id: "hauptschulabschluss",
              name: "Hauptschulabschluss",
              rang: 1,
            },
            {
              id: "mittlere_reife_mittlerer_bildungsabschluss",
              name: "Mittlere Reife / Mittlerer Bildungsabschluss",
              rang: 2,
            },

            {
              id: "werkrealschulabschluss",
              name: "Werkrealschulabschluss",
              rang: 2,
            },

            {
              id: "berufsfachschule",
              name: "Berufsfachschule",
              rang: 2,
            },

            {
              id: "diplom_fh_ba",
              name: "Diplom (FH, BA)",
              rang: 4,
            },

            {
              id: "diplom_univ",
              name: "Diplom (Univ.)",
              rang: 4,
            },

            {
              id: "staatlich_geprüfter_fachwirt",
              name: "Staatlich geprüfter Fachwirt",
              rang: 4,
            },

            {
              id: "staatlich_geprüfter_betriebswirt",
              name: "Staatlich geprüfter Betriebswirt",
              rang: 4,
            },

            {
              id: "magister",
              name: "Magister",
              rang: 3,
            },

            {
              id: "hoechster_abschluss_steuerfachangestellter",
              name: "Steuerfachangestellter",
              rang: 4,
            },
            {
              id: "hoechster_abschluss_wirtschaftspruefer",
              name: "Wirtschaftsprüfer",
              rang: 4,
            },

            {
              id: "bilanzbuchhalter_ihk_vwa",
              name: "Bilanzbuchhalter IHK/VWA",
              rang: 3,
            },
            {
              id: "hoechster_abschluss_controller_ihk_vwa",
              name: "Controller IHK/VWA",
              rang: 3,
            },
          ],
          branchen: [
            {
              id: "branche_anlagenbau",
              name: "Anlagenbau",
            },
            {
              id: "branche_automotive",
              name: "Automotive",
            },
            {
              id: "branche_banken",
              name: "Banken",
            },
            {
              id: "branche_beratung",
              name: "Beratung/Consulting",
            },
            {
              id: "branche_bildung",
              name: "Bildung",
            },
            {
              id: "branche_chemie",
              name: "Chemie & Pharma",
            },
            {
              id: "branche_dienstleistung",
              name: "Dienstleistung",
            },
            {
              id: "branche_energie",
              name: "Energie & Umwelt",
            },
            {
              id: "branche_forschung",
              name: "Forschung & Entwicklung",
            },
            {
              id: "branche_gesundheitswesen",
              name: "Gesundheitswesen & Soziales",
            },
            {
              id: "branche_grosshandel",
              name: "Großhandel",
            },
            {
              id: "branche_handwerk",
              name: "Handwerk",
            },
            {
              id: "branche_immobilien",
              name: "Immobilien",
            },
            {
              id: "branche_it",
              name: "IT",
            },
            {
              id: "branche_kultur_unterhaltung_veranstaltung",
              name: "Kultur, Unterhaltung, Veranstaltung",
            },
            {
              id: "branche_logistik",
              name: "Logistik",
            },
            {
              id: "branche_marketing",
              name: "Marketing & PR",
            },
            {
              id: "branche_medizintechnik",
              name: "Medizintechnik",
            },
            {
              id: "branche_maschinenbau",
              name: "Maschinenbau",
            },
            {
              id: "branche_oeffentliche_verwaltung",
              name: "Öffentliche Verwaltung / Städtische Betriebe",
            },
            {
              id: "branche_recht",
              name: "Recht & Steuern",
            },
            {
              id: "branche_software",
              name: "Software",
            },
            {
              id: "branche_steuerberatung",
              name: "Steuerberatung",
            },
            {
              id: "branche_telekommunikation",
              name: "Telekommunikation",
            },
            {
              id: "branche_textilien",
              name: "Textilien & Mode",
            },
            {
              id: "branche_transport",
              name: "Transport",
            },
            {
              id: "branche_verein",
              name: "Verein",
            },
            {
              id: "branche_verlagswesen",
              name: "Verlagswesen",
            },
            {
              id: "branche_versicherung",
              name: "Versicherung",
            },
            {
              id: "branche_werkzeugbau",
              name: "Werkzeugbau",
            },
            {
              id: "branche_wirtschaftspruefung",
              name: "Wirtschaftsprüfung",
            },
            // {
            //   id: "branche_sonstiges",
            //   name: "Sonstiges",
            // },
          ],
          unternehmensgroesse: [
            {
              id: "startup",
              name: "Startup",
            },
            {
              id: "mittelstand_k_250",
              name: "Mittelstand <250 Mitarbeiter",
            },
            {
              id: "mittelstand_g_250",
              name: "Mittelstand >250 Mitarbeiter",
            },
            {
              id: "konzern",
              name: "Konzern >5000 Mitarbeiter",
            },
          ],
          pdl_dienstleistungen: [
            {
              id: "personalvermittlung",
              name: "Personalvermittlung",
            },
            {
              id: "arbeitnehmerueberlassung",
              name: "Arbeitnehmerüberlassung",
            },
            {
              id: "interim_management",
              name: "Interim Management",
            },
            {
              id: "headhunting",
              name: "Headhunting",
            },
          ],
          wechselmotiv: [
            {
              id: "wunsch_veraenderung",
              name: "Wunsch nach Veränderung",
            },
            {
              id: "berufliche_weiterentwicklung",
              name: "Berufliche Weiterentwicklung",
            },
            {
              id: "neue_herausforderung ",
              name: "Neue Herausforderung ",
            },
            {
              id: "befristete_stelle",
              name: "Befristete Stelle",
            },
            {
              id: "schwierigkeiten_arbeitgeber",
              name: "Schwierigkeiten aktueller Arbeitgeber",
            },
            {
              id: "berufseinstieg",
              name: "Berufseinstieg",
            },
            {
              id: "umzug",
              name: "Umzug",
            },
            {
              id: "sonstiges",
              name: "Sonstiges",
            },
          ],
          fuehrerscheine: [
            {
              id: "fuehrerscheine_stapler",
              name: "Staplerschein",
              iconClass: "fas fa-forklift",
            },
            {
              id: "fuehrerscheine_am",
              name: "AM",
              iconClass: "fas fa-motorcycle",
            },
            {
              id: "fuehrerscheine_a1",
              name: "A1",
              iconClass: "fas fa-motorcycle",
            },
            {
              id: "fuehrerscheine_a2",
              name: "A2",
              iconClass: "fas fa-motorcycle",
            },
            {
              id: "fuehrerscheine_a",
              name: "A",
              iconClass: "fas fa-motorcycle",
            },
            {
              id: "fuehrerscheine_b1",
              name: "B1",
              iconClass: "fas fa-trailer",
            },
            {
              id: "fuehrerscheine_b",
              name: "B",
              iconClass: "fas fa-car-side",
            },
            {
              id: "fuehrerscheine_be",
              name: "BE",
              iconClass: "fas fa-trailer",
            },
            {
              id: "fuehrerscheine_c1",
              name: "C1",
              iconClass: "fas fa-truck",
            },
            {
              id: "fuehrerscheine_c",
              name: "C",
              iconClass: "fas fa-truck-moving",
            },
            {
              id: "fuehrerscheine_c1e",
              name: "C1E",
              iconClass: "fas fa-truck-moving",
            },
            {
              id: "fuehrerscheine_ce",
              name: "CE",
              iconClass: "fas fa-truck-container",
            },
            {
              id: "fuehrerscheine_d1",
              name: "D1",
              iconClass: "fas fa-bus",
            },
            {
              id: "fuehrerscheine_d",
              name: "D",
              iconClass: "fas fa-bus-alt",
            },
            {
              id: "fuehrerscheine_d1e",
              name: "D1E",
              iconClass: "fas fa-bus",
            },
            {
              id: "fuehrerscheine_de",
              name: "DE",
              iconClass: "fas fa-bus-alt",
            },
            {
              id: "fuehrerscheine_l",
              name: "L",
              iconClass: "fas fa-tractor",
            },
            {
              id: "fuehrerscheine_t",
              name: "T",
              iconClass: "fas fa-tractor",
            },
            {
              id: "fuehrerscheine_adr",
              name: "ADR (Gefahrgut)",
              iconClass: "fas fa-radiation",
            },
          ],
          schichtbereitschaft: [
            {
              id: "schichtbereitschaft_nichtvorhanden",
              name: "Nicht vorhanden",
              iconClass: "fas fa-times",
            },
            {
              id: "schichtbereitschaft_fruehschicht",
              name: "Frühschicht",
              iconClass: "fas fa-sunrise",
            },
            {
              id: "schichtbereitschaft_spaetschicht",
              name: "Spätschicht",
              iconClass: "fas fa-sunset",
            },
            {
              id: "schichtbereitschaft_nachtschicht",
              name: "Nachtschicht",
              iconClass: "fas fa-moon",
            },
          ],

          einschraenkungen: [
            {
              id: "einschraenkungen_schwertragen",
              name: "Nicht schwer tragen",
            },
            {
              id: "einschraenkungen_langestehen",
              name: "Nicht lange stehen",
            },
            {
              id: "einschraenkungen_langesitzen",
              name: "Nicht lange sitzen",
            },
            {
              id: "einschraenkungen_visuell",
              name: "visuelle Einschränkungen",
            },
            {
              id: "einschraenkungen_hohe_lautstaerke",
              name: "Keine zu hohe Lautstärke",
            },
          ],
          remote: [
            {
              id: "ja",
              name: "Ja",
            },
            {
              id: "nein",
              name: "Nein",
            },
            {
              id: "ausschliesslich",
              name: "Ausschließlich Remote",
            },
          ],
        },
        { merge: true }
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    console.log("ok");
  }
}
