import { Injectable, NgZone } from '@angular/core';
import {
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import {
  Auth,
  createUserWithEmailAndPassword,
  signInAnonymously,
  signInWithEmailAndPassword,
  User,
  FacebookAuthProvider,
} from '@angular/fire/auth';
import {
  connectFirestoreEmulator,
  doc,
  docData,
  Firestore,
  setDoc,
  Timestamp,
} from '@angular/fire/firestore';
import { merge, ReplaySubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { CONSTANTS } from './constants';
import { ToastService } from './toast.service';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCustomToken,
  signInWithPopup,
} from '@firebase/auth';
import { environment } from '../../environments/environment';
import { setTimeout$ } from '../_functions/timeout';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: User | null = null;
  public userObservable = new ReplaySubject<User | null>(1);
  public userData?: any;
  public userDataObservable = new ReplaySubject<any>();
  public subdomain?: string;
  public pdlId?: string;
  public production = false;

  queryParams: any = {};

  datenschutzConfirmed = false;

  public unsubscribeAll: Subject<void> = new Subject();

  unsubscribeAllFrom(subscriptionList: any, stop = false) {
    if (subscriptionList) {
      if (subscriptionList.unsubscribe) {
        subscriptionList.unsubscribe();
      } else {
        for (const i in subscriptionList) {
          this.unsubscribeAllFrom(subscriptionList[i]);
        }
      }
    }
  }

  // public claims
  // public get typ() {

  //   if (!this.claims) {
  //     return null
  //   }
  //   if (this.claims.isBewerber) {
  //     return 'bewerber'
  //   }
  //   if (this.claims.isPdl) {
  //     return 'pdl'
  //   }
  //   if (this.claims.isUnternehmen) {
  //     return 'unternehmen'
  //   }
  // }
  // private _lastCommittedClaims

  subscription?: any;

  public loading = true;
  public typLoading = false;

  public static isAdmin = () => hasCustomClaim('admin');
  public static allowKandiJobAd = () =>
    hasCustomClaim('staff') || hasCustomClaim('admin');
  get isAdmin() {
    return AuthService.isAdmin();
  }
  get allowKandiJobAd() {
    return AuthService.allowKandiJobAd();
  }
  public static redirectUnauthorizedToLogin = () =>
    redirectUnauthorizedTo(['login']);
  public static redirectLoggedInToItems = () => redirectLoggedInTo(['/']);

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient,
    public angularFireAuth: Auth,
    public firestore: Firestore,
    public toastService: ToastService,
    private title: Title,
    private metaTagService: Meta
  ) {
    if (environment.firebase.useLocal) {
      connectFirestoreEmulator(this.firestore, 'localhost', 8080);
    }
  }
  auth = getAuth();

  initAuth() {
    this.angularFireAuth.onAuthStateChanged(async (user) => {
      this.loading = false;
      console.log('USER', user?.uid, user);
      this.user = user;
      this.userObservable.next(this.user);

      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      if (user) {
        this.subscription = docData(
          doc(this.firestore, `${CONSTANTS.USER_COLLECTION}/${user.uid}`)
        ).subscribe((userData) => {
          console.log('userData', userData);
          this.userData = userData;
          this.userDataObservable.next(this.userData);
          // console.log('userData', userData)
          // if (userData) {
          //   Tawk_API.setAttributes({
          //     name: userData.name,
          //     email: userData.email,
          //     hash: 'hash-value'
          //   });
          //   if (userData?._lastCommittedClaims) {
          //     if (!this._lastCommittedClaims ||
          //       ((userData._lastCommittedClaims as firestore.Timestamp).seconds != (this._lastCommittedClaims as firestore.Timestamp).seconds ||
          //         (userData._lastCommittedClaims as firestore.Timestamp).nanoseconds != (this._lastCommittedClaims as firestore.Timestamp).nanoseconds)
          //     ) {
          //       console.log('Refreshing token')
          //       this.user.getIdToken(true)
          //     }
          //     this._lastCommittedClaims = userData._lastCommittedClaims
          //   }

          //   if (!userData.typ) {
          //     this.ngZone.run(() =>
          //       this.router.navigate(['/user/typ'])
          //     )
          //   } else if (userData && ((!userData.vorname && !userData.nachname) || userData.firstSetup)) {
          //     this.ngZone.run(() =>
          //       console.log('redirectSetup'),
          //       // this.router.navigate(['/user/steps'])
          //     )
          //   }
          //   console.log("newData", userData);
          // }
        });
      }
    });
  }


  setQueryParam(key:string, value:any) {
    this.queryParams[key] = value;
    localStorage.setItem("pa-" + key, value);

    // this.saveParamsToUser();
  }

  removeQueryParam(key:string) {
    delete this.queryParams[key];
    localStorage.removeItem("pa-" + key);
  }

  logout = async () => {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.unsubscribeAll.next();

    setTimeout$(async () => {
      this.userData = null;
      await this.angularFireAuth.signOut();
      window.location.href = '/login';
    }, 50);
  };

  get isLoggedIn() {
    return this.auth.currentUser ? true : false;
  }

  // CLAIMS
  setTyp(type: 'pdl' | 'unternehmen' | 'bewerber') {
    if (!this.user) throw new Error('No User to update Type');

    // this.typLoading = true
    return setDoc(
      doc(this.firestore, this.user.uid),
      {
        typ: type,
      },
      { merge: true }
    )
      .then((res) => {
        console.log('typ updated');
        this.ngZone.run(() => this.router.navigate(['/user/steps']));
      })
      .catch((err) => {
        console.log(err);
        this.toastService.error(
          'Es ist ein Fehler aufgetreten',
          'Bitte erneut versuchen'
        );
      });
  }

  signInWithEmailAndPassword = (email: string, password: string) =>
    new Promise(async (resolve, reject) => {
      try {
        const r = await signInWithEmailAndPassword(
          this.auth,
          email,
          password
        ).catch((err) => reject(err));
        if (r) {
          // this.navCtrl.setRoot('HomePage');
          return resolve(r);
        }
      } finally {
        return reject();
      }
    });

  signInAnonymously() {
    return signInAnonymously(this.auth)
      .then(async (user) => {
        console.log('login success', user);
        return user;
      })
      .catch((error) => {
        console.log('login fail');
      });
  }

  async signInWithToken(token: string) {
    console.log('jwt', token);
    const r = await signInWithCustomToken(this.auth, token).catch((err) =>
      Promise.reject(err)
    );
    if (r) {
      return Promise.resolve(r);
    } else {
      return Promise.reject();
    }
  }

  async register(email: string, password: string, typ = 'bewerber') {
    try {
      localStorage.setItem('acceptCookie', 'true');
      const r = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      if (r) {
        const data: any = { typ, email };
        console.log(this.queryParams);
        if (this.queryParams?.kref) {
          data.ref = this.queryParams?.kref;
        }
        const ip = await this.getIpAdress();
        await setDoc(doc(this.firestore, r.user.uid), data, { merge: true });

        await setDoc(
          doc(
            this.firestore,
            CONSTANTS.USER_COLLECTION,
            r.user.uid,
            'privateSystem',
            'register'
          ),
          { date: Timestamp.now(), ip: ip }
        );
        return Promise.resolve(r);
      }
      return Promise.reject(r);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loginWithFacebook() {
    const provider = new FacebookAuthProvider();
    provider.addScope('email');
    provider.addScope('user_birthday');
    provider.addScope('user_hometown');
    provider.addScope('user_gender');

    const res = await signInWithPopup(this.auth, provider).catch((err) =>
      Promise.reject(err)
    );

    const profile: any = res.user;
    const data: Partial<any> = {
      vorname: profile.first_name,
      nachname: profile.last_name,
      email: res.user.email,
      typ: 'bewerber',
    };

    if (profile.birthday) {
      const split = profile.birthday.split('/');
      if (split.length === 3) data.jahrgang = split[2];
    }

    setDoc(doc(this.firestore, CONSTANTS.USER_COLLECTION, res.user.uid), data, {
      merge: true,
    });

    // if (res.operationType) {
    //   this.router.navigate(['/setup']);
    // }
    return Promise.resolve(res);
  }

  async loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    // provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");
    // provider.addScope("https://www.googleapis.com/auth/user.organization.read");
    // provider.addScope("https://www.googleapis.com/auth/user.gender.read");
    // provider.addScope("https://www.googleapis.com/auth/user.emails.read");
    // provider.addScope("https://www.googleapis.com/auth/user.birthday.read");
    // provider.addScope("https://www.googleapis.com/auth/user.addresses.read");

    const res = await signInWithPopup(this.auth, provider).catch((err) =>
      Promise.reject(err)
    );

    const profile: any = res.user;
    const data: Partial<any> = {
      vorname: profile.given_name,
      nachname: profile.family_name,
      email: res.user.email,
      typ: 'bewerber',
    };

    setDoc(doc(this.firestore, CONSTANTS.USER_COLLECTION, res.user.uid), data, {
      merge: true,
    });

    // if (res.additionalUserInfo?.isNewUser) {
    //   this.router.navigate(['/setup']);
    // }
    return Promise.resolve(res);
  }

  async loginWithApple() {
    const provider = new OAuthProvider('apple.com');

    const res = await signInWithPopup(this.auth, provider).catch((err) =>
      Promise.reject(err)
    );

    const data: Partial<any> = {
      email: res.user.email,
      typ: 'bewerber',
    };

    if (res.user?.displayName) {
      data.vorname = res.user.displayName?.split(' ').slice(0, -1).join(' ');
      data.nachname = res.user.displayName?.split(' ').slice(-1).join(' ');
    }

    setDoc(doc(this.firestore, CONSTANTS.USER_COLLECTION, res.user.uid), data, {
      merge: true,
    });

    // if (res.additionalUserInfo?.isNewUser) {
    //   this.router.navigate(['/setup']);
    // }
    return Promise.resolve(res);
  }

  async loginWithMicrosoft() {
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('openid');
    provider.addScope('email');
    provider.addScope('profile');

    const res = await signInWithPopup(this.auth, provider).catch((err: any) =>
      Promise.reject(err)
    );

    const profile: any = res.user;
    const data: any = {
      vorname: profile.givenName,
      nachname: profile.surname,
      email: res.user.email,
      typ: 'bewerber',
    };

    if (profile.mobilePhone) {
      data.mobil = '0049' + profile.mobilePhone;
    } else if (profile.businessPhones?.length > 0) {
      data.mobil = '0049' + profile.businessPhones[0];
    }

    setDoc(doc(this.firestore, CONSTANTS.USER_COLLECTION, res.user.uid), data, {
      merge: true,
    });
    // if (res.additionalUserInfo?.isNewUser) {
    //   this.router.navigate(['/setup']);
    // }
    return Promise.resolve(res);
  }

  getIpAdress = async () =>
    new Promise((resolve, reject) =>
      this.http
        .get('https://api.ipify.org/?format=json')
        .pipe(take(1))
        .subscribe((res: any) => resolve(res.ip))
    );

  setTitle = (title: string | null, tags = null) => {
    this.title.setTitle(title ? `${title} | talaneo` : 'talaneo - EINFACH. NEU. DURCHSTARTEN.');

    if (tags) {
      this.metaTagService.addTags(tags);
    }
  };
}
