export const environment = {
  production: true,
  firebase: {
    useLocal: false,
    apiKey: 'AIzaSyCLTNGdj30cGc29h6Rq1Or1wKxSr_5i1k8',
    authDomain: 'kandi-board.firebaseapp.com',
    projectId: 'kandi-board',
    storageBucket: 'kandi-board.appspot.com',
    messagingSenderId: '571276875501',
    appId: '1:571276875501:web:2501b4b5b5734f7b681ee4',
    measurementId: 'G-FSTJRLK45Q'
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyCSBdelPVH7lPFd3dIMUdWmr7xZGa3slIY',
}
