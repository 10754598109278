import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { CONSTANTS } from './constants';

@Injectable({
  providedIn: 'root',
})
export class ElasticService {
  constructor(public http: HttpClient) {}

  getPredictions = (pdl:string|null = null) =>
    new Promise((resolve, reject) => {
      var data: any = {
        query: { match_all: {} },
        fields: ['name'],
        size: 100,
        "_source": false
      };

      this.runRequest(data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });

  search = (query: any, pdl = null, size:number|null = 100) =>
    new Promise((resolve, reject) => {
      console.log('query', query);
      var data: any = {
        query: query,
      };
      if(size){
        data.size = size
      }

      this.runRequest(data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });

  private runRequest = (data: any) =>
    new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: 'ApiKey ' + CONSTANTS.elastic.apiKey,
      });
      headers.append('Content-Type', 'application/json');

      const index = CONSTANTS.elastic.jobAdIndex;

      this.http
        .post(`${CONSTANTS.elastic.node}/${index}/_search`, data, {
          headers: headers,
        })
        .pipe(take(1))
        .subscribe((res) => {
          resolve(res);
        });
    });
}
