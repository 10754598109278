import { Injectable } from '@angular/core';
import { doc, getDoc, onSnapshot } from '@angular/fire/firestore';
import { Firestore } from '@angular/fire/firestore';
import { ReplaySubject } from 'rxjs';
import { setTimeout$ } from '../_functions/timeout';
import { Unternehmen } from '../_models/user/user.model';
import { AuthService } from './auth.service';
import { CONSTANTS } from './constants';
import { ElasticService } from './elastic.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public static: any;
  public staticMap: any;
  public staticPdl: any;
  public staticPdlMap: any;
  tooltips: any;
  staticLoad = new ReplaySubject(1);
  staticPdlLoad = new ReplaySubject(1);
  showPreloader = true;
  showMenu = false;
  currentPage = 'home';
  public unternehmen?: { [key: string]: Unternehmen };

  predictionsStellenanzeige: any;

  public isMobile?: boolean;
  public isMobileObservable = new ReplaySubject(1);

  constructor(
    private firestore: Firestore,
    private authService: AuthService,
    private elasticService: ElasticService
  ) {
    this.loadStatic();
  }

  openMenu() {
    this.showMenu = !this.showMenu;
  }

  loadStatic = () => {
    getDoc(doc(this.firestore, 'static', 'app')).then((ref) => {
      const data: any = ref.data();
      console.log('loadStatic', data);
      this.static = data;
      this.tooltips = {};
      // console.log(this.static)

      this.staticMap = {};
      for (const key in this.static) {
        if (Array.isArray(this.static[key]) && key != 'skill_categories') {
          this.staticMap[key] = this.arrayToObject(this.static[key]);
          this.tooltips[key] = this.static[key]
            .map((k: any) => `${k.name}: ${k.info}`)
            .join('<br/>');
        } else if (key == 'stellenbezeichnungen') {
          // this.staticMap[key] = this.arrayToObject(Object.values(this.static[key]).flat())  //https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
          this.staticMap[key] = this.arrayToObject(
            this.flattenDeep(Object.values(this.static[key]))
          );
          this.static.stellenbezeichnungen_list = this.flattenDeep(
            Object.values(this.static[key])
          ).sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
        }
      }

      // console.log(this.staticMap)
      // if (data?.skill_categories) {
      //   this.skill_categories = data.skill_categories;
      //   for (const category of this.skill_categories) {
      //     if (category.sub_categories) {
      //       for (const sub_category of category.sub_categories) {
      //         if (sub_category.skills) {
      //           this.handleSkills(sub_category.skills, category, sub_category);
      //         }
      //       }
      //     }
      //     if (category.skills) {
      //       this.handleSkills(category.skills, category);
      //     }
      //   }
      // }
      CONSTANTS.staticMap = this.staticMap;

      // this.buildIndexStatic();
      this.staticLoad.next(this.static);
      console.log(this.static);
    });
  };

  loadStaticPDL = (pdlId: string) => {
    getDoc(doc(this.firestore, 'users', pdlId, 'static', 'app')).then((ref) => {
      const data: any = ref.data();
      console.log('staticPdl', data);

      if (data) {
        if (data.stellenbezeichnungen) {
          this.staticPdl = data;

          this.staticPdlMap = {};
          for (const key in this.staticPdl) {
            if (Array.isArray(this.staticPdl[key])) {
              this.staticPdlMap[key] = this.arrayToObject(this.staticPdl[key]);
            } else if (key == 'stellenbezeichnungen') {
              this.staticPdlMap[key] = this.arrayToObject(
                this.flattenDeep(Object.values(this.staticPdl[key]))
              );
              this.staticPdl.stellenbezeichnungen_list = this.flattenDeep(
                Object.values(this.staticPdl[key])
              );
            }
          }
        }
      }
      this.staticPdlLoad.next(this.staticPdl);
    });
  };

  loadPredictions(pdlId: string) {
    this.elasticService.getPredictions(pdlId).then((res: any) => {
      const object: any = {};
      for (let i of res?.hits.hits) {
        object[i.fields.name] = true;
      }
      this.predictionsStellenanzeige = Object.keys(object).sort(
        (a: any, b: any) => (a > b ? 1 : -1)
      );
      console.log('predictionsStellenanzeige', this.predictionsStellenanzeige);
    });
  }

  loadUnternehmen() {
    if (!this.authService.user?.uid) {
      setTimeout$(() => this.loadUnternehmen(), 1000);
    }
    onSnapshot(
      doc(
        this.firestore,
        'users',
        this.authService.user!.uid,
        'maps',
        'company'
      ),
      (ref) => {
        const data = ref?.data();
        if (data) {
          this.unternehmen = data?.map;
        }
      }
    );
  }

  public arrayToObject = (array: any[], newClass?: any) => {
    return array.reduce((obj, item) => {
      // obj[item.id] = newClass ? Object.assign(new newClass, item) : item
      obj[item.id] = newClass ? new newClass(item) : item;
      return obj;
    }, {});
  };

  public arrayToObjectElastic = (array: any[], newClass?: any) => {
    return array.reduce((obj, item) => {
      let object = item._source;
      // obj[item.id] = newClass ? Object.assign(new newClass, item) : item
      obj[item._id] = newClass ? new newClass(object) : object;
      obj[item._id].scoreElastic = item._score;
      obj[item._id].id = item._id;
      return obj;
    }, {});
  };

  public flattenDeep = (arr1: any) =>
    arr1.reduce(
      (acc: any, val: any) =>
        Array.isArray(val)
          ? acc.concat(this.flattenDeep(val))
          : acc.concat(val),
      []
    );
}
