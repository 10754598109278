export class CONSTANTS {
    public static paths = {
      apply: "bewerben",
      culturalfit: 'culturalfit',
      search: "suchen",
    };
    public static elastic = {
      jobAdIndex: "",
      node: "",
      apiKey: "",
    };
    public static storage = {
      basePath: "",
    };

    public static staticMap:any

    public static rechtsformen = [
      "Einzelunternehmen",
      "GbR",
      "e.K.",
      "OHG",
      "KG",
      "GmbH",
      "UG",
      "AG",
      "GmbH & Co KG",
    ];
  
    public static proximityhashOptions = {
      precision: 6,
      georaptorFlag: true,
      minlevel: 3,
      maxlevel: 7,
      approxHashCount: true,
    };
  
    public static typAppointment = {
      phone: { id: "phone", name: "Telefonat", icon: "fas fa-phone" },
      personal: {
        id: "personal",
        name: "persönliches Bewerbungsgespräch",
        icon: "fas fa-user-friends",
      },
      online: { id: "online", name: "Online Meeting", icon: "fas fa-laptop" },
    };
  
    public static typAvailability = {
      woechentlich: {
        id: "woechentlich",
        name: "Wiederkehrend",
        icon: "fas fa-repeat-alt",
      },
      zeitraum: {
        id: "zeitraum",
        name: "Zeitraum",
        icon: "fas fa-calendar-week",
      },
    };
  
    public static weekDays = [
      {
        id: "so",
        name: "Sonntag",
      },
      {
        id: "mo",
        name: "Montag",
      },
      {
        id: "di",
        name: "Dienstag",
      },
      {
        id: "mi",
        name: "Mittwoch",
      },
      {
        id: "do",
        name: "Donnerstag",
      },
      {
        id: "fr",
        name: "Freitag",
      },
      {
        id: "sa",
        name: "Samstag",
      },
    ];
  
    public static weekDaysMap = {
      mo: {
        name: "Montag",
      },
      di: {
        name: "Dienstag",
      },
      mi: {
        name: "Mittwoch",
      },
      do: {
        name: "Donnerstag",
      },
      fr: {
        name: "Freitag",
      },
    };
  
    public static typJobAd = {
      link: { id: "link", name: "Link", icon: "fas fa-link" },
      pdf: { id: "pdf", name: "angehängtes Dokument", icon: "fas fa-file-pdf" },
      text: { id: "text", name: "Text", icon: "fas fa-align-justify" },
      kein: { id: "kein", name: "Ohne", icon: "fas fa-ban" },
    };
  
    public static geschlechter = [
      { id: "m", name: "Männlich" },
      { id: "w", name: "Weiblich" },
      { id: "d", name: "Divers" },
    ];
  
    public static bewerber_status = [
      { id: "aktiv", name: "Aktiv", info: "Aktiv auf Jobsuche" },
      {
        id: "latent",
        name: "Latent",
        info: "Nicht aktiv auf der Suche, aber offen für Anfragen",
      },
      { id: "inaktiv", name: "Inaktiv", info: "Aktuell nicht auf der Suche" },
    ];
  
    public static bewerber_privacy = [
      {
        id: "immer",
        name: "Immer",
        info: "Ab Suche",
        iconClass: "fas fa-shield-alt",
      },
      {
        id: "nach_annahme",
        name: "Nach Annahme",
        info: "Daten sind erst nach Annahme einer Anfrage sichtbar",
        iconClass: "fas fa-shield",
      },
    ];
  
    public static user_typ = [
      { id: "bewerber", name: "Bewerber", info: "Bewerber auf Jobsuche" },
      {
        id: "pdl",
        name: "Personaldienstleister",
        info: "Personaldienstleister zur Verwaltung mehrerer Kandidatinnen und Kandidaten",
      },
      {
        id: "unternehmen",
        name: "Unternehmen",
        info: "Unternehmen auf Suche nach Kandidatinnen und Kandidaten",
      },
    ];
  
    public static typRankParticipant = {
      teamleader: {
        id: "teamleader",
        name: "Teamleiter",
        iconClass: "fas fa-phone-square",
      },
      headofdepartment: {
        id: "headofdepartment",
        name: "Abteilungsleiter",
        iconClass: "fas fa-globe",
      },
      areamanager: {
        id: "areamanager",
        name: "Bereichsleiter",
        iconClass: "fas fa-map-marker-alt",
      },
    };
  
    public static SEARCH_QUERY_LIMIT = 10;
    public static USER_COLLECTION = "users";
    public static REQUEST_COLLECTION = "request";
  }
  